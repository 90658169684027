import React from "react";
import "./style.scss";
const Service = () => {
	return (
		<div className="card-container">
			<div className="card-main-content">
				<div className="card-description">
					<h3>Soluções</h3>
					<p>
						Descubra nossas abrangentes soluções e selecione aquela que melhor
						atende às suas necessidades específicas. Estamos dedicados a
						oferecer opções que otimizem e superem suas expectativas.
					</p>
				</div>
				<div className="card-content">
					<div className="card">
						<div className="card-number">1</div>
						<h3>Farmácia</h3>
						<p>
							Nossos serviços de farmácia abrangem uma ampla variedade de
							atividades relacionadas à gestão de medicamentos e promoção da
							saúde. Oferecemos soluções especializadas nas seguintes áreas:
							<ul>
								<li>- Dispensação de medicamentos</li>
								<li>- Gerenciamento de estoque</li>
							</ul>
							Estamos comprometidos em proporcionar cuidados farmacêuticos de
							qualidade, garantindo o acesso a medicamentos essenciais e a
							gestão eficiente de suas necessidades de saúde.
						</p>
					</div>

					<div className="card">
						<div className="card-number">2</div>
						<h3>Tecnologias da Informação & Comunicação</h3>
						<p>
							Na vanguarda das Tecnologias da Informação e Comunicação (TICs),
							estamos empenhados em oferecer soluções inovadoras que impulsionam
							a transformação digital. Nossa expertise abrange desde
							desenvolvimento de software até infraestrutura avançada de redes,
							permitindo que organizações alcancem eficiência operacional e
							inovação. Comprometemo-nos a criar soluções personalizadas e
							escaláveis.
						</p>
					</div>

					<div className="card">
						<div className="card-number">3</div>
						<h3>Transporte de passageiro</h3>
						<p>
							Comprometemo-nos a oferecer soluções de transporte confiáveis e
							eficientes, sendo parte integrante da mobilidade moderna. Nossa
							missão é facilitar deslocamentos seguros, sustentáveis e pontuais
							para passageiros e cargas, contribuindo para a conectividade
							global. Valorizamos a qualidade do serviço e a satisfação dos
							nossos clientes, garantindo uma jornada tranquila e confortável em
							cada viagem.
						</p>
					</div>
					<div className="card">
						<div className="card-number">4</div>
						<h3>Restauração</h3>
						<p>
							A restauração compreende a indústria de serviços dedicada à
							preparação e comercialização de alimentos e bebidas para consumo
							em locais públicos, tais como restaurantes, cafés, bares,
							lanchonetes, padarias e estabelecimentos similares. Nosso
							compromisso é oferecer uma experiência gastronômica excepcional,
							refletindo nossa paixão pela culinária e satisfação de nossos
							clientes.
						</p>
					</div>
					<div className="card">
						<div className="card-number">5</div>
						<h3>Agência de viagem & turismo</h3>
						<p>
							Somos uma agência especializada em proporcionar experiências
							únicas de viagem e turismo. Nossa missão é criar roteiros
							personalizados e inesquecíveis, adequados às preferências de cada
							cliente. Comprometemo-nos a tornar cada viagem uma jornada
							memorável, oferecendo serviços excepcionais, assistência contínua
							e descobertas inspiradoras ao redor do mundo.
						</p>
					</div>
					<div className="card">
						<div className="card-number">6</div>
						<h3>Recrutamento & Gestão de pessoal</h3>
						<p>
							Nosso serviço de recrutamento está centrado na identificação e
							seleção de profissionais altamente qualificados, alinhados com as
							necessidades e cultura da sua organização. Priorizamos o match
							perfeito entre talentos e vagas, contribuindo para o crescimento e
							sucesso contínuo de sua equipe. Estamos empenhados em oferecer
							soluções eficazes de recrutamento que impulsionem sua empresa para
							o próximo nível.

							Apresentamos cuidadosamente os candidatos e facilitamos discussões
							sobre suas propostas, fornecendo uma abordagem estratégica para a
							gestão eficaz de talentos em sua organização.
						</p>
					</div>
					<div className="card">
						<div className="card-number">7</div>
						<h3>Venda de viaturas & Veículos Motorizados</h3>
						<p>
							Especializados na comercialização de veículos motorizados,
							oferecemos uma ampla gama de opções que incluem viaturas,
							motociclos e ciclomotores. Nosso compromisso é fornecer uma
							experiência de compra transparente e satisfatória, auxiliando
							nossos clientes a encontrar a opção perfeita para atender suas
							necessidades de mobilidade. Contamos com uma equipe dedicada e
							experiente para orientar em cada etapa do processo, garantindo a
							satisfação e confiança em sua escolha.
						</p>
					</div>
					<div className="card">
						<div className="card-number">8</div>
						<h3>Pesca</h3>
						<p>
							Nosso compromisso na área da pesca é proporcionar uma experiência
							excepcional para os entusiastas desse universo aquático.
							Oferecemos equipamentos especializados, orientação e expertise
							para garantir aventuras de pesca bem-sucedidas. Desde a escolha do
							equipamento até dicas valiosas, estamos aqui para tornar cada
							momento de pesca memorável, incentivando a paixão pelo esporte e o
							respeito pelo meio ambiente marinho. Viva a emoção da pesca
							conosco! 🎣
						</p>
					</div>
					<div className="card">
						<div className="card-number">9</div>
						<h3>Derivados de Petróleo</h3>
						<p>
							Exploramos a ampla gama de derivados de petróleo, fornecendo
							produtos essenciais e combustíveis que impulsionam a mobilidade e
							a indústria moderna. Comprometemo-nos a garantir a qualidade e a
							disponibilidade de nossos derivados, cumprindo normas rigorosas e
							promovendo práticas sustentáveis. Contribuímos para a eficiência e
							o progresso em diversas esferas da vida cotidiana, sendo parte
							integrante do desenvolvimento socioeconômico.
						</p>
					</div>
					<div className="card">
						<div className="card-number">10</div>
						<h3>Consultória Financeira</h3>
						<p>
							Oferecemos protocolos avançados, indo além dos modelos de
							engajamento tradicionais, para garantir uma estratégia de preços e
							cobrança alinhada e eficaz. Ajudamos a otimizar suas práticas
							financeiras, proporcionando um impacto positivo em seus
							resultados.
						</p>
					</div>
					<div className="card">
						<div className="card-number">11</div>
						<h3>Comércio Geral</h3>
						<p>
							Navegue pelo nosso amplo leque de soluções destinadas ao setor de
							comércio. Oferecemos estratégias e suporte especializado para
							impulsionar suas operações, alavancar vendas e garantir eficiência
							operacional em um mercado dinâmico.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Service;
