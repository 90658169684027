import React from "react";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import user from "../assets/img/user.jpeg";
import testimonials from "../assets/img/12.jpg";
import Leonardo from "../assets/img/leonardo.jpg";
import Deolinda from "../assets/img/14.jpg";
export const links = [
	{
		id: 1,
		url: "/",
		text: "home",
	},
	{
		id: 2,
		url: "/about",
		text: "sobre nós",
	},
	{
		id: 3,
		url: "/service",
		text: "serviços",
	},
	{
		id: 4,
		url: "/contact",
		text: "contactos",
	},
];

export const social = [
	{
		id: 1,
		name: "Facebook",
		url: "https://web.facebook.com/EstreladoTafe?_rdc=1&_rdr",
		icon: <FaFacebook size={35} />,
	},
	{
		id: 2,
		name: "Linkedin",
		url: "https://www.linkedin.com/company/estrela-do-tafe/",
		icon: <FaLinkedin size={35} />,
	},
	{
		id: 3,
		name: "Instagram",
		url: "https://www.instagram.com/estreladotafe/",
		icon: <AiFillInstagram size={35} />,
	},
];

const people = [
	{
		id: 1,
		image: Deolinda,
		name: "Deolinda Pipas",
		title: "Radialista",
		quote:
			"Trabalhar com a Estrela do Tafe tem sido uma experiência gratificante. Profissionalismo e dedicação em cada projeto. Recomendo sem hesitação!🌟",
	},
	{
		id: 2,
		image: user,
		name: "Mário Batalha",
		title: "Desenvolvedor de software",
		quote:
			"Foi uma experiência incrível trabalhar com a Estrela do Tafe. Equipe altamente profissional e entrega excelente. Recomendo!",
	},
	{
		id: 3,
		image: Leonardo,
		name: "Leonardo Ferreira",
		title: "IT Manager",
		quote:
			"Experiência excepcional com a equipe da Estrela do Tafe. Profissionalismo e eficiência inigualáveis. Recomendo vivamente! 🌟",
	},
	{
		id: 4,
		image: testimonials,
		name: "José Tati",
		title: "CEO da Ta Ligado - Empresa de Entretenimento",
		quote:
			"Parceiros incríveis! A Estrela do Tafe tem sido fundamental para o sucesso de nossos projetos de entretenimento. Profissionalismo e inovação andam lado a lado aqui 🌟",
	},
];

export default people;
