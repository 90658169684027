import React from "react";

import "./style.scss";
import aboutImg from "../../assets/img/13.jpg";

const Description = () => {
	return (
		<section className="service">
			<div className="ceo-content">
				<div>
					<img className="ceo-img" src={aboutImg} alt="Miguel Neto - CEO" />
				</div>
				<div>
					<h2>Miguel G. Neto</h2>
					<span>(CEO)</span>
					<p style={{ color: "#000",  paddingBottom: '0.5rem' }}>
						{" "}
						Com mais de uma década de dedicação e expertise
						na gestão e administração de projetos, Miguel Neto é um profissional
						altamente capacitado, sendo reconhecido por sua excelência como
						inspetor auditor, monitor de informática e especialista em mobilizar
						e influenciar pessoas. Ao longo de sua trajetória profissional
						marcante, Miguel Neto se destacou pelas notáveis habilidades de
						liderança e gestão de recursos humanos, e nas relações interpessoais
						que foram fundamentais para o sucesso das organizações em que atuou.
						Possuindo uma ampla experiência em segurança no trabalho, ele
						contribuiu significativamente para garantir um ambiente seguro e
						produtivo para todos os colaboradores.{" "}
					</p>
					<p style={{ color: "#000",  paddingTop: '0.5rem' }}>Sua jornada profissional é
						sempre marcada por ocupações de destaque e pelo seu compromisso em
						impulsionar o crescimento e a prosperidade das organizações sob sua
						liderança, e colaboração. Miguel Neto é uma fonte inesgotável de
						inspiração, e sua liderança visionária continua a moldar o futuro de
						nossa empresa.{" "}</p>
				</div>
			</div>
			<div className="service-container">
				<h2>Nossa História</h2>
				<p>
					Estrela do Tafe, é uma empresa angolana que nasce da necessidade de
					responder às solicitações de fornecimento de serviços aos nossos
					parceiros, para tal foi desenvolvida a melhor oferta de capital humano
					com a preocupação pelo desenvolvimento de um trabalho prático e seguro
					de forma a prestar um serviço de qualidade através de uma força de
					trabalho sustentável.
				</p>
			</div>
		</section>
	);
};

export default Description;
