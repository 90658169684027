import React, {Suspense} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.scss";
import Error from "./pages/Error";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Service from "./pages/service";
import About from "./pages/about";
import Navbar from './components/navbar';
import Footer from './components/footer/';


const App = () => {
  return (
    <div className="App">
      <Suspense fallback={null}>
        <BrowserRouter>
				<Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/service" element={<Service />} />
            <Route path="/about" element={<About />} />
						<Route path='*' element={<Error />} />
          </Routes>
				<Footer />
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;