import React from "react";
import { GiMissileLauncher } from "react-icons/gi";
import { FiTarget, FiKey } from "react-icons/fi";

import "./style.scss";

const About = () => {
	return (
		<div className="about">
			<div className="container">
				<div className="mission">
					<GiMissileLauncher size={35} color="blue" />
					<h3>Missão</h3>
					<p>
						Nossa missão é oferecer serviços que atendam às rigorosas demandas
						de qualificação e excelência na prestação de serviços, acrescentando
						significativo valor às empresas que servimos.
					</p>
				</div>
				<div className="vision">
					<FiTarget size={35} color="red" />
					<h3>Visão</h3>
					<p>
						Almejamos ser uma referência nacional na gestão de relacionamento
						com os clientes, destacando-nos pelo compromisso com a excelência e
						pela criação de laços duradouros baseados na confiança e na
						satisfação mútua.
					</p>
				</div>
				<div className="value">
					<FiKey size={35} color="green" />
					<h3>Valores</h3>
					<p>
						Nosso compromisso é pautado pelos valores da qualificação,
						eficiência, qualidade e rigor, os quais norteiam cada ação que
						empreendemos. Buscamos incessantemente aprimorar nossos serviços,
						superando as expectativas dos nossos clientes.
					</p>
				</div>
			</div>
		</div>
	);
};

export default About;
