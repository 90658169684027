import React from "react";
import "./style.scss";
import 'animate.css';

const Solution = () => {
  return (
    <section className="partner">
    </section>
  );
};

export default Solution;
