import React from "react";
import { Link } from 'react-router-dom';
import NotFound from '../../assets/img/404.gif';
const Error = () => {
	return (
		<div className="contact">
      <img src={NotFound} alt="404 página não encontrada"  className="not-found"/>
			<h3>Página não encontrada</h3>
      <p>Verique a sua conexão de internet ou se o endereço existe</p>
      <Link to="/">Voltar para a página principal</Link>
		</div>
	);
};

export default Error;