import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.scss";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const Slide1 = require("../../assets/img/04.jpg");
const Slide2 = require("../../assets/img/07.jpg");
const Slide3 = require("../../assets/img/02.jpg");
const Slide4 = require("../../assets/img/rent-car.jpeg");
const Slide5 = require("../../assets/img/car-flet.avif");
const Slide6 = require("../../assets/img/farmacy.jpg");
const Slide7 = require("../../assets/img/construction.jpg");

const images = [
	{
		id: 1,
		url: Slide1,
		alternativeText: "Estrela do Tafe - grupo de trabalho",
	},
	{
		id: 1,
		url: Slide2,
		alternativeText: "Estrela do Tafe - grupo de trabalho",
	},
	{
		id: 3,
		url: Slide3,
		alternativeText: "Estrela do Tafe - grupo de trabalho",
	},
	{
		id: 4,
		url: Slide4,
		alternativeText: "Estrela do Tafe - grupo de trabalho",
	},
	{
		id: 5,
		url: Slide5,
		alternativeText: "Estrela do Tafe - grupo de trabalho",
	},
	{
		id: 6,
		url: Slide6,
		alternativeText: "Estrela do Tafe - grupo de trabalho",
	},
	{
		id: 7,
		url: Slide7,
		alternativeText: "Estrela do Tafe - grupo de trabalho",
	},
];

const Carroussel = () => {
	return (
		<section className="carroussel">
			<Swiper
				spaceBetween={30}
				centeredSlides={true}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
				}}
				navigation={false}
				modules={[Autoplay, Pagination, Navigation]}
				className="mySwiper"
			>
				{images.map((item) => {
					return (
						<SwiperSlide key={item.id}>
							<img
								className="imgSlider"
								src={item.url}
								alt={item.alternativeText}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</section>
	);
};
export default Carroussel;
