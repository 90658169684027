import React from "react";
import "./style.scss";
import { useForm, ValidationError } from "@formspree/react";
import success from '../../assets/img/success-image.png';

const Contact = () => {
	const [state, handleSubmit] = useForm("mbjvwkld");
	if (state.succeeded) {
		return (
			<div className="success-message">
			<img src={success} alt="sucess icon"  className="icon" />
				<h2>Obrigado por entrar em contacto!</h2>
				<p>Vamos responder a sua preocupação tão logo seja possível.</p>
			</div>
		);
		// alert(
		// 	"Obrigado por entrar em contacto, vamos responder a sua preocupação tão logo seja possível"
		// );
	}
	return (
		<div className="contact">
			<div className="contact-container">
					<div className="contact-form">
						<h3>Contacte-nos</h3>
						<p>Em que podemos te ajudar?</p>
						<form onSubmit={handleSubmit}>
							<div className="input-form">
								<input
									id="email"
									type="text"
									name="name"
									placeholder="Nome completo"
									required
								/>
								<ValidationError
									prefix="name"
									field="name"
									errors={state.errors}
								/>
								<input
									id="email"
									type="email"
									name="email"
									placeholder="Email"
									required
								/>
								<ValidationError
									prefix="Email"
									field="email"
									errors={state.errors}
								/>
							</div>
							<div>
								<textarea
									id="message"
									name="message"
									placeholder="Deixe aqui a sua mensagem"
									required
								/>
								<ValidationError
									prefix="Message"
									field="message"
									errors={state.errors}
								/>
							</div>
							<button type="submit" disabled={state.submitting}>
								Submit
							</button>
						</form>
					</div>
			</div>
		</div>
	);
};

export default Contact;
