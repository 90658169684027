import React from "react";
import { FaWhatsapp } from "react-icons/fa";

import Carroussel from "../../components/carroussel";
import Description from "../../components/description";
import Solution from "../../components/solution";
import Testimonials from "../../components/testimonials";
import Location from "../../components/location";

import './style.scss';


const Home = () => {
	return (
		<div className="home">
			<div className="whatsapp-button-container">
			<ul>
				<li>
					<a
          href="https://api.whatsapp.com/send?l=pt-pt_BR&phone=+244934879050&text=Olá, gostaria de falar com o/a atendente."
					aria-label="Entre em contacto pelo whatsapp"
          target="blank"
        >
          <FaWhatsapp size={30} color="#FFF" className="whatsapp-icon" />
        </a>
				</li>
			</ul>
      </div>
			<Carroussel />
		 	<Description /> 
			<Solution />
			<Testimonials /> 
			<Location /> 
		</div>
	);
};

export default Home;
