import React from "react";
import "./style.scss";

const Location = () => {
	return (
		<section className="location">
			<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.7244999548248!2d13.276330699999999!3d-8.997478499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a521db18003f475%3A0x4353896458aa37a1!2sEstrela%20do%20Tafe!5e0!3m2!1spt-PT!2sao!4v1697561325556!5m2!1spt-PT!2sao" 
			width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
			title="Estrela do Tafe">
			</iframe>
		</section>
	);
};

export default Location;
