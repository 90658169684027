import React from "react";
import Logo from "../../assets/img/logo-white.jpg";
import { social } from "../../data";
import "./style.scss";

const Footer = () => {
	let date = new Date();
	let year = date.getFullYear();
	return (
		<footer>
			<div className="footer-info">
				<div className="footer-social">
					<div className="logo-content">
						<img
							src={Logo}
							className="footer-logo"
							alt="Estrela do Tafe logo"
						/>
						<h4>Aqui você brilha mais</h4>
					</div>
					<div>
						<ul className="social-icons">
              <h4>Siga-nos!</h4>
							{social.map((socialIcon) => {
								const { id, url, name, icon } = socialIcon;
								return (
									<li key={id}>
										<a href={url} aria-label={name} target="blank">
											{icon}
										</a>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="footer-contact">
					<div className="footer-offices">
						<h4>Nossos escritórios</h4>
						<ul>
							<li>
								Luanda, Centralidade do Kilamba Bloco S23, 2º andar apt. 23
							</li>
							<li>Cabinda Av. Duque de Chiaze, C/S nº</li>
							<li>Malange Hotel Palanca loja nº 01</li>
						</ul>
					</div>
					<div className="footer-mobile">
						<h4>Contactos</h4>
						<ul>
							<li>+244 934 879 050</li>
							<li>info@estreladotafe.co.ao</li>
							<li>Segunda á sexta-feira - 08h00 às 16h00</li>
						</ul>
					</div>
          <div className="footer-mobile">
						<h4>Recrutamento</h4>
						<ul>
							<li>Seleção de pessoal</li>
							<li>Indicação de pessoal</li>
							<li>Contratação</li>
						</ul>
					</div>
          <div className="footer-mobile">
						<h4>Farmácia</h4>
						<ul>
							<li>Venda de medicamentos</li>
							<li>Gestão de stock</li>
						</ul>
					</div>
				</div>
        <div className="footer-contact">
					<div className="footer-offices">
						<h4>Viaturas</h4>
						<ul>
							<li>
								Venda de viatuaras
							</li>
							<li>Aluguer de viaturas</li>
						</ul>
					</div>
					<div className="footer-mobile">
						<h4>Comércio geral </h4>
						<ul>
							<li>Venda de material de escritório</li>
							<li>Venda de derivados de petróleo</li>
						</ul>
					</div>
          <div className="footer-mobile">
						<h4>Consultória</h4>
						<ul>
							<li>Consultória financeira</li>
							<li>Auditória</li>
							<li>Consultória de TI</li>
						</ul>
					</div>
          <div className="footer-mobile">
						<h4>Turismo</h4>
						<ul>
							<li>Agência de viagem</li>
							<li>Planeamento de viagem</li>
							<li>Rotas de viagem</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="copyright">
				<p>
					Copyright &copy; {year} Estrela do Tafe - Todos os direitos reservado.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
